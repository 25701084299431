/**
 * Created by jwjorgen on 25/11/2018.
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';


export const AllRecipesList = props => {

    let alphabet = [];

    for (let i = 0; i<26; i++) {
        let chr = String.fromCharCode(65 + i);
        alphabet.push(chr);
    }

    alphabet.push('Æ');
    alphabet.push('Ø');
    alphabet.push('Å');

    return (
        <Grid container spacing={24}>

            {
                alphabet.map((letter, index) => {

                    let recipesMatchingLetter = props.allRecipes.filter(recipe => recipe.title.charAt(0).toUpperCase() === letter);

                    return(

                        <Grid item xs={12} sm={6} md={4} key={index}>

                            <h3 style={{color: '#ff709c'}}>{letter}</h3>

                            {
                                recipesMatchingLetter.map((recipe, index) => {
                                    return (
                                        <div className="linkContainer" key={index}>
                                            <Link to={'/recipes/' + recipe.url}>
                                                <div className="link">
                                                    {recipe.title}
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })
                            }

                        </Grid>

                    )
                })
            }

        </Grid>
    );
};

export default AllRecipesList;