/**
 * Created by jwjorgen on 12/11/2018.
 */

import './styles.css';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { firebase, auth } from '../firebase';
import * as routes from '../constants/routes';

import AuthUserContext from '../context/AuthUserContext';
import SearchBarV3 from '../containers/SearchBarV3';
import ButtonSignIn from '../buttons/ButtonSignIn';
import ButtonSignOut from '../buttons/ButtonSignOut';
import ButtonAllRecipes from '../buttons/ButtonAllRecipes';
import ButtonFeelingLucky from '../buttons/ButtonFeelingLucky';
import ButtonAdvanced from '../buttons/ButtonAdvanced';
//import ButtonHome from '../buttons/ButtonHome';


class LandingPage extends Component {

    state = {
        allRecipesUrls: []
    };

    /*
    BREAK POINTS

     xs, extra-small:   0px or larger
     sm, small:         600px or larger
     md, medium:        960px or larger
     lg, large:         1280px or larger
     xl, extra-large:   1920px or larger

     */

    async componentDidMount() {
        let allRecipesUrls = await firebase.getAllRecipeUrls();

        this.setState({
            allRecipesUrls: [...allRecipesUrls]
        })
    };

    getRandomRecipeUrl() {

        let numRecipes = this.state.allRecipesUrls.length;

        let randomInt = Math.floor(Math.random() * numRecipes); //0 to numRecipes-1

        return this.state.allRecipesUrls[randomInt];

    };

    goHome = (event) => {
        event.preventDefault();
        this.props.history.push(routes.LANDING);
    };

    signIn = (event) => {
        event.preventDefault();
        this.props.history.push(routes.LOGIN);
    };

    signOut = (event) => {
        event.preventDefault();
        this.props.history.push(routes.RECIPES);
        auth.doSignOut();
    };

    render() {

        let height = this.props.location.pathname==='/'? "landingPageHeight" : "notLandingPageHeight";

        return (
            <div className="landingPageBG">

                <div className={"landingPage " + height}
                     style={{backgroundColor: this.props.location.pathname==='/' ? '#ff709c00' : '#ff709cFF'}}>

                    <Grid container className="logoAndLoginContainer" spacing={24} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>

                        <Grid item className="loginContainer" xs={10} sm={11}>

                            <div className="logoContainer"
                                 onClick={ (event) => this.goHome(event) }>

                                <img src={require('../img/LogoPinkLong.png')}
                                     style={{opacity: this.props.location.pathname==='/' ? 1 : 0}}
                                     alt="Logo"
                                     height="40" />

                            </div>

                            {this.props.authUser ?
                                <div className="loginOrOutContainer" onClick={ (event) => this.signOut(event) }>
                                    <ButtonSignOut />
                                </div>
                                :
                                <div className="loginOrOutContainer" onClick={ (event) => this.signIn(event) }>
                                    <ButtonSignIn />
                                </div>
                            }


                        </Grid>

                    </Grid>

                    <Grid container className="landingPageContainer" spacing={0}>

                        <Grid item className="landingPageJumbo" xs={10} sm={6} md={4}>

                            <div className="searchBarContainer">
                                <SearchBarV3 history={this.props.history} />
                            </div>

                            <div className="landingPageLinksContainer">
                                <ButtonAllRecipes />
                                <ButtonFeelingLucky randomRecipeUrl={this.getRandomRecipeUrl()} />
                                <ButtonAdvanced />
                            </div>

                        </Grid>

                    </Grid>

                    {this.props.location.pathname === '/' ?
                        <div className="madeByText">
                            <p className="">PHOTO BY BRITT H. JØRGENSEN</p>
                        </div>
                        :
                        null
                    }

                </div>
            </div>
        );
    }
}


// To get the context (authentication), the component has to be wrapped in a consumer
export default withRouter(React.forwardRef((props, ref) => (
    <AuthUserContext.Consumer>
        {authUser => <LandingPage {...props} authUser={authUser} ref={ref} />}
    </AuthUserContext.Consumer>
)));
