/**
 * Created by jwjorgen on 12/11/2018.
 */

import React, { Component } from 'react';
import withAuthorization from '../higherOrderComponents/withAuthorization';
import Grid from '@material-ui/core/Grid';

import SignOutButton from '../containers/SignOut';


class AdminPage extends Component {

    render() {

        return (
            <Grid container className="notLandingPage adminPage" spacing={24}>

                <Grid item className="adminPageContainer" xs={10} md={8}>

                    <p>AdminPage</p>
                    <SignOutButton />

                </Grid>

            </Grid>
        );
    }
}


// Condition used in the authorization process
// Checks whether the user object is null or not
const authCondition = (authUser) => !!authUser;

// Using the higher order component 'withAuthorization' to protect the page
export default withAuthorization(authCondition)(AdminPage);