/**
 * Created by jwjorgen on 25/11/2018.
 */

import React, { Component } from 'react';
import AuthUserContext from '../context/AuthUserContext';

import AllRecipesList from '../components/AllRecipesList';
import { withRouter } from "react-router-dom";
import ButtonNewRecipe from '../buttons/ButtonNewRecipe';
import * as routes from '../constants/routes';


class AllRecipes extends Component {

    newRecipe = (event) => {
        event.preventDefault();
        this.props.history.push(routes.NEW_RECIPE);
    };

    render() {

        return (
            <div className="allRecipesPageContainer">

                <div style={{width: '100%', paddingBottom: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>

                    <h3 style={{borderBottom: "solid 1px black"}}>Alle Oppskrifter</h3>

                    {this.props.authUser
                        ? <div onClick={ (event) => this.newRecipe(event) }>
                            <ButtonNewRecipe />
                        </div>
                        : null
                    }

                </div>

                <AllRecipesList allRecipes={this.props.allRecipes} />

            </div>
        );
    }
}

// To get the context (authentication), the component has to be wrapped in a consumer
export default withRouter(React.forwardRef((props, ref) => (
    <AuthUserContext.Consumer>
        {authUser => <AllRecipes {...props} authUser={authUser} ref={ref} />}
    </AuthUserContext.Consumer>
)));