/**
 * Created by jwjorgen on 13/11/2018.
 */

import React from 'react';

import { firebase } from '../firebase';
import AuthUserContext from '../context/AuthUserContext';


/**
 * Higher order component that takes care of, and implementing an event listener for, authentication
 * throughout the application
 */
const withAuthentication = (Component) => {
    class WithAuthentication extends React.Component {

        constructor(props) {
            super(props);

            this.state = {
                authUser: null,
            };
        }

        // A listener that will react to -every- change in authentication state, and thus re-render the app
        componentDidMount() {
            firebase.auth.onAuthStateChanged(authUser => {
                authUser
                    ? this.setState({ authUser })
                    : this.setState({ authUser: null });
            });
        }

        render() {
            const { authUser } = this.state;

            return (
                <AuthUserContext.Provider value={authUser}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }

    return WithAuthentication;
};

export default withAuthentication;
