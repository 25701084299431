/**
 * Created by jwjorgen on 19/11/2018.
 */

import React from 'react';


export const InstructionList = props => {

    return(
        <div style={{marginVertical: '10px', height: '100%', overflow: 'hidden', padding: '10px 0'}}>

                <div dangerouslySetInnerHTML={{__html: props.instructions}} />

        </div>
    );
};

export default InstructionList;