/**
 * Created by jwjorgen on 12/11/2018.
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import LoginContainer from '../containers/LoginContainer';


class LoginPage extends Component {

    render() {

        return (

            <div>

                <Grid container className="notLandingPage" spacing={0}>

                    <Grid className="loginPage" item xs={10} md={9}>

                        <LoginContainer history={this.props.history} />

                    </Grid>
                </Grid>

            </div>

        );
    }
}

export default withRouter(LoginPage);