/**
 * Created by jwjorgen on 18/11/2018.
 */

import React from 'react';

export const RecipeTitle = props => {

    return(
        <div style={{textAlign: 'center', color: 'black', padding: '15px', overflow: 'hidden'}}>

            <h2>{props.title}</h2>

        </div>
    );
};

export default RecipeTitle;