/**
 * Created by jwjorgen on 16/11/2018.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';


const ButtonFeelingLucky = (props) => {

    return(
        <div className="button links lucky">
            <Link to={'/recipes/' + props.randomRecipeUrl}>
                LYKKETREFF
            </Link>
        </div>
    );
};

export default ButtonFeelingLucky;