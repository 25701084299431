/**
 * Created by jwjorgen on 12/11/2018.
 */

import React, { Component } from 'react';
import { firebase } from '../firebase';
import Grid from '@material-ui/core/Grid';
import Ingredients from '../containers/Ingredients';
import Instructions from '../containers/Instructions';
import Loading from '../components/Loading';
import { withRouter } from "react-router-dom";
import * as routes from '../constants/routes';
import AuthUserContext from '../context/AuthUserContext';
import RecipeInfo from '../components/RecipeInfo';
import RecipeTitle from '../components/RecipeTitle';
import ButtonEdit from '../buttons/ButtonEdit';
import ButtonDelete from '../buttons/ButtonDelete';


class RecipePage extends Component {

    state = {
        loading: true,
        recipeUrl: null,
        recipe: null,
        recipeImageUrl: null
    };


    async componentDidMount () {
        const {recipeUrl} = this.props.match.params;

        let recipe = await firebase.getRecipe(recipeUrl);
        let recipeImageUrl = null;

        if(recipe.image) {
            recipeImageUrl = await firebase.getRecipeImage(recipe.id);
        }

        // Parse ingredients list to a list of objects
        let ingredientListString = recipe.ingredients;
        let ingredientList = JSON.parse(ingredientListString);

        let updatedRecipe = {
            ...recipe,
            ingredients: ingredientList
        };

        this.setState({
            loading: false,
            recipe: updatedRecipe,
            recipeImageUrl: recipeImageUrl
        });
    }


    /**
     * Update recipe if url/recipeId have changed
     */
    async componentDidUpdate(prevProps, prevState) {

        if (prevState.recipeUrl !== this.state.recipeUrl) {
            let recipe = await firebase.getRecipe(this.state.recipeUrl);
            let recipeImageUrl = null;

            if(recipe.image) {
                recipeImageUrl = await firebase.getRecipeImage(recipe.id);
            }

            // Parse ingredients list to a list of objects
            let ingredientListString = recipe.ingredients;
            let ingredientList = JSON.parse(ingredientListString);

            let updatedRecipe = {
                ...recipe,
                ingredients: ingredientList
            };

            this.setState({
                loading: false,
                recipe: updatedRecipe,
                recipeImageUrl: recipeImageUrl
            });
        }
    }

    deleteRecipe = (recipe, event) => {
        let confirmation = window.confirm('Er du sikker på at du vil slette oppskriften?');

        if (confirmation) {
            firebase.deleteRecipe(recipe);
            this.props.history.push(routes.RECIPES);
        } else {
            event.preventDefault();
        }
    };

    editRecipe = (recipe, event) => {
        event.preventDefault();
        this.props.history.push('/recipes/' + recipe.url + '/edit');
    };


    /**
     * Check if props have been updated, and if so - update state (recipeId)
     */
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.match.params.recipeUrl !== prevState.recipeUrl){
            return {
                recipeUrl: nextProps.match.params.recipeUrl,
                loading: true
            };
        }
        else return null;
    }


    render() {

        let recipe = this.state.recipe;
        let recipeImageUrl = this.state.recipeImageUrl;

        return (
            <div style={{overflow: 'hidden'}}>
            {this.state.loading
                ?
                <div className="notLandingPage">
                    <Grid  container className="loadingPage" spacing={0}>
                        <Grid item className="loadingPageContainer" xs={10} md={8}>
                            <Loading />
                        </Grid>
                    </Grid>
                </div>
                :
                <Grid container className="notLandingPage" spacing={0}>

                    <Grid item className="recipePage" xs={10} md={9}>

                        <Grid container className="recipePageContainer" spacing={24}>

                            {/*
                            {recipeImageUrl ?
                                <Grid item className="" xs={12}>
                                    <div className="recipePageHeader"
                                         style={{backgroundImage: `url(${recipeImageUrl})`}}/>
                                </Grid>
                                :
                                null
                            }

                            <Grid item className="" xs={12}>
                                <RecipeTitle title={recipe.title} />
                            </Grid>
                             */}

                             <Grid item className="imageAndTitleContainer" xs={12}>
                                 {recipeImageUrl ?
                                     <div className="recipePageHeader"
                                            style={{backgroundImage: `url(${recipeImageUrl})`}}/>
                                     :
                                     null
                                 }

                                 <RecipeTitle title={recipe.title}/>
                             </Grid>

                            {this.props.authUser
                                ?
                                <Grid item className="" xs={12} style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <div onClick={ (event) => this.editRecipe(recipe, event) }>
                                        <ButtonEdit />
                                    </div>

                                    <div onClick={ (event) => this.deleteRecipe(recipe, event) }>
                                        <ButtonDelete />
                                    </div>
                                </Grid>
                                :
                                null
                            }

                            <Grid item className="" xs={12}>
                                <RecipeInfo recipe={recipe} />
                            </Grid>

                            <Grid item className="" xs={12} md={4}>
                                <Ingredients ingredients={recipe.ingredients} numPeople={recipe.numPeople} />
                            </Grid>

                            <Grid item className="" xs={12} md={8}>
                                <Instructions instructions={recipe.instructions} />
                            </Grid>

                        </Grid>


                    </Grid>

                </Grid>
            }
            </div>
        );
    }
}

// To get the context (authentication), the component has to be wrapped in a consumer
export default withRouter(React.forwardRef((props, ref) => (
    <AuthUserContext.Consumer>
        {authUser => <RecipePage {...props} authUser={authUser} ref={ref} />}
    </AuthUserContext.Consumer>
)));