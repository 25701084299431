/**
 * Created by jwjorgen on 01/12/2018.
 */

import React from 'react';
import {Editor, EditorState, RichUtils, Modifier, SelectionState} from 'draft-js';
import 'draft-js/dist/Draft.css';
import './styles.css';

import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import DOMPurify from 'dompurify'; // For sanitizing html input


class RichEditor extends React.Component {

    constructor(props) {
        super(props);

        // If there already exists instructions, init with that
        this.state = props.initInstructions
            ? {editorState: EditorState.createWithContent(stateFromHTML(props.initInstructions))}
            : {editorState: EditorState.createEmpty()};

        this.focus = () => this.refs.editor.focus();
        this.onChange = (editorState) => {
            this.setState({editorState});

            let html = stateToHTML(editorState.getCurrentContent());
            let htmlSanitized = DOMPurify.sanitize(html);
            this.props.onInstructionsChange(htmlSanitized); // Convert to html (sanitized)
        };

        this.handleKeyCommand = (command) => this._handleKeyCommand(command);
        this.onTab = (e) => this._onTab(e);
        this.toggleBlockType = (type) => this._toggleBlockType(type);
        this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
    }

    updateContentState = (newContentState) => {
        let newEditorState = EditorState.createWithContent(newContentState);

        // HAve to move the cursor to the end of text
        let newContentStateMoveSelectionToEnd = moveSelectionToEnd(newEditorState);

        this.setState({
            editorState: newContentStateMoveSelectionToEnd
        })
    };

    _handleKeyCommand(command) {
        const {editorState} = this.state;
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }

    _onTab(e) {
        const maxDepth = 4;
        this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
    }

    _toggleBlockType(blockType) {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }

    render() {

        const {editorState} = this.state;

        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        let className = 'RichEditor-editor';
        let contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return(
            <div className="RichEditor-root">
                <BlockStyleControls
                    editorState={editorState}
                    onToggle={this.toggleBlockType}
                />
                <InlineStyleControls
                    editorState={editorState}
                    updateContentState={this.updateContentState}
                    onToggle={this.toggleInlineStyle}
                />
                <div className={className} onClick={this.focus}>
                    <Editor
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        onChange={this.onChange}
                        onTab={this.onTab}
                        placeholder="Fremgangsmåte..."
                        ref="editor"
                        spellCheck={true}
                    />
                </div>
            </div>
        );
    }
}

export default RichEditor;


// Custom overrides for "code" style.
const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
    'STRIKETHROUGH': {
        textDecoration: 'line-through',
    },
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': return 'RichEditor-blockquote';
        default: return null;
    }
}

class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
              {this.props.label}
            </span>
        );
    }
}

class CelsiusButton extends React.Component {
    constructor() {
        super();
        this.onPress = (e) => {
            e.preventDefault();
            let editorState = this.props.editorState;

            let contentState = editorState.getCurrentContent();
            let selectionState = editorState.getSelection(); // Is this where the 'cursor' is at?
            let currentStyle = editorState.getCurrentInlineStyle();

            let newContentState = Modifier.insertText(
                contentState, //ContentState
                selectionState, //TargetRange
                '\u00b0C', //text (unicode for celsius degree)
                currentStyle //inlineStyle
                //entityKey?: ?string
            );

            this.props.updateContentState(newContentState);
        };
    }

    render() {
        let className = 'RichEditor-styleButton';

        return (
            <span className={className} onMouseDown={this.onPress}>
              &deg;C
            </span>
        );
    }
}

const BLOCK_TYPES = [
    {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'H5', style: 'header-five'},
    {label: 'H6', style: 'header-six'},
    {label: 'Sitat', style: 'blockquote'},
    {label: 'Punktliste', style: 'unordered-list-item'},
    {label: 'Numerert Liste', style: 'ordered-list-item'},
];

const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

let INLINE_STYLES = [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'},
    {label: 'Monospace', style: 'CODE'},
    {label: 'Strikethrough', style: 'STRIKETHROUGH'}
];

const InlineStyleControls = (props) => {
    let currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map(type =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
            <CelsiusButton
                editorState={props.editorState}
                updateContentState={props.updateContentState}
            />
        </div>
    );
};


// Moves the cursor to the end of the content
const moveSelectionToEnd = (editorState) => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();

    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();

    // On Chrome and Safari, calling focus on contenteditable focuses the
    // cursor at the first character. This is something you don't expect when
    // you're clicking on an input element but not directly on a character.
    // Put the cursor back where it was before the blur.
    const selection = new SelectionState({
        anchorKey: key,
        anchorOffset: length,
        focusKey: key,
        focusOffset: length,
    });
    return EditorState.forceSelection(editorState, selection);
};