/**
 * Created by jwjorgen on 12/11/2018.
 */

export const LANDING = '/';
export const ADMIN = '/admin';
export const LOGIN = '/login';
export const RECIPES = '/recipes';
export const RECIPE = '/recipes/:recipeUrl';
export const EDIT_RECIPE = '/recipes/:recipeUrl/edit';
export const NEW_RECIPE = '/recipes/new';