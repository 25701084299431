/**
 * Created by jwjorgen on 12/11/2018.
 */

import React, { Component } from 'react';
import { firebase } from '../firebase';
import Grid from '@material-ui/core/Grid';
import Loading from '../components/Loading';
import AlleRecipes from '../containers/AllRecipes';


class RecipesPage extends Component {

    state = {
        loading: true,
        recipes: []
    };

    async componentDidMount () {

        let allRecipes = await firebase.getAllRecipes();

        this.setState({
            loading: false,
            recipes: allRecipes
        });
    }

    render() {

        return (
            <div style={{overflow: 'hidden'}}>
                {this.state.loading
                    ?
                    <div className="notLandingPage">
                        <Grid  container className="loadingPage" spacing={0}>
                            <Grid item className="loadingPageContainer" xs={10} md={8}>
                                <Loading />
                            </Grid>
                        </Grid>
                    </div>
                    :
                    <Grid container className="notLandingPage" spacing={0}>

                        <Grid item className="allRecipesPage" xs={10} md={9}>

                            <AlleRecipes allRecipes={this.state.recipes}/>

                        </Grid>

                    </Grid>
                }
            </div>
        );
    }
}

export default RecipesPage;