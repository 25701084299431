/**
 * Created by jwjorgen on 18/11/2018.
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';


export const IngredientsList = props => {

    // This sorting function is supposedly not used, but have to be here to get the correct order of ingredient categories
    props.ingredients.sort(function(a, b) {
        return ((a.category < b.category) ? -1 : ((a.category > b.category) ? 1 : 0));
    });

    let categoryList = ['main'];

    props.ingredients.map((ingredient, index) => {
       if (categoryList.indexOf(ingredient.category) === -1) {
           categoryList.push(ingredient.category)
       }

       return(null);
    });

    return(
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '10px 0'}}>

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: '5px'}}>
                <p>Porsjoner:</p>
                <input className=""
                       style={{minWidth: '25px', maxWidth: '35px', marginLeft: '10px'}}
                       type="number"
                       id="numPeople"
                       placeholder="0"
                       required={true}
                       value={props.displayPeople}
                       min={1}
                       onChange={ event => props.updateDisplayPeople(event.target.value) }/>
            </div>

            {categoryList.map((category, index) => {

                return(
                    <div key={index} style={{marginBottom: '15px'}}>

                        <p className="subIngredientTitle">{category==='main' ? '' : category}</p>

                        {
                            props.ingredients.map((ingredient, index) => {

                                if (ingredient.category === category) {

                                    let amount = (ingredient.amount / props.numPeople) * props.displayPeople;

                                    amount = Math.round( amount * 10 ) / 10; // Round to one decimal place

                                    return(
                                        <Grid key={index} container spacing={0} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>

                                            <Grid item xs={2} sm={1} md={2}>
                                                <p>- {amount === 0 ? '' : amount}</p>
                                            </Grid>

                                            <Grid item xs={2} sm={1} md={2}>
                                                <p>{ingredient.unit}</p>
                                            </Grid>

                                            <Grid item xs={8} sm={10} md={8}>
                                                <p>{ingredient.name}</p>
                                            </Grid>

                                        </Grid>
                                    );

                                }

                                return(null);

                            })
                        }
                    </div>
                );
            })}

        </div>
    );
};

export default IngredientsList;