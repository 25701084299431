/**
 * Created by jwjorgen on 19/11/2018.
 */

import React, { Component } from 'react';

import InstructionList from '../components/InstructionList';


class Instructions extends Component {


    render() {

        return (
            <div style={{height: '100%', width: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>

                <div style={{borderBottom: "solid 1px black"}}>
                    <h3>Fremgangsmåte</h3>
                </div>

                <InstructionList instructions={this.props.instructions} />

            </div>
        );
    }
}

export default Instructions;