/**
 * Created by jwjorgen on 01/12/2018.
 */

import React, { Component } from 'react';
import { firebase } from '../firebase';
import Grid from '@material-ui/core/Grid';
import Loading from '../components/Loading';
import { withRouter } from "react-router-dom";
import withAuthorization from '../higherOrderComponents/withAuthorization';
import { DeleteOutlined } from '@material-ui/icons';


import RichEditor from '../components/RichEditor';


const allergener = ['Glutenfri', 'Laktosefri', 'Melkefri', 'Nottefri', 'Sukkerfri', 'Vegetar']; //Remember to update constructor
// NB! Nottefri becuase no ae, oe, aa in ASCII
const difficulties = ['Lett', 'Middels', 'Vanskelig'];

class EditRecipePage extends Component {

    constructor(props) {

        super(props);
        this.state = {
            localImageFile: null,
            localImageUrl: null,
            loading: true,
            recipeUrl: null,

            recipe: {
                title: '',
                dietary: {
                    glutenfri: false,
                    laktosefri: false,
                    vegetar: false,
                    sukkerfri: false,
                    nottefri: false,
                    melkefri: false
                },
                difficulty: '',
                duration: {
                    minutes: '',
                    category: ''
                },
                ingredients: [],
                instructions: '',
                meal: 'Annet',
                numPeople: '',
                image: false
            },

            recipeImageUrl: null,
            saving: false
        };

        this.onChange = (editorState) => this.setState({editorState});
        this.handleChange = this.handleChange.bind(this)
    }


    /**
     * Updates image
     */
    handleChange(event) {
        this.setState({
            localImageUrl: URL.createObjectURL(event.target.files[0]),
            localImageFile: event.target.files[0]
        })
    }

    async componentDidMount () {
        const {recipeUrl} = this.props.match.params;

        // If recipeUrl doesn't exist, create new recipe
        if (!recipeUrl) {
            this.setState({
                loading: false,
            });

        } else {
            let recipe = await firebase.getRecipe(recipeUrl);
            let recipeImageUrl = null;

            if(recipe.image) {
                recipeImageUrl = await firebase.getRecipeImage(recipe.id);
            }

            // Parse ingredients list to a list of objects
            let ingredientListString = recipe.ingredients;
            let ingredientList = JSON.parse(ingredientListString);

            let updatedRecipe = {
                ...recipe,
                ingredients: ingredientList,
            };

            this.setState({
                loading: false,
                recipe: updatedRecipe,
                recipeImageUrl: recipeImageUrl
            });
        }
    }


    /**
     * Update recipe if url/recipeId have changed
     */
    async componentDidUpdate(prevProps, prevState) {

        if (prevState.recipeUrl !== this.state.recipeUrl) {
            let recipe = await firebase.getRecipe(this.state.recipeUrl);
            let recipeImageUrl = null;

            if(recipe.image) {
                recipeImageUrl = await firebase.getRecipeImage(recipe.id);
            }

            // Parse ingredients list to a list of objects
            let ingredientListString = recipe.ingredients;
            let ingredientList = JSON.parse(ingredientListString);

            let updatedRecipe = {
                ...recipe,
                ingredients: ingredientList,
            };

            this.setState({
                loading: false,
                recipe: updatedRecipe,
                recipeImageUrl: recipeImageUrl
            });
        }
    }


    /**
     * Check if props have been updated, and if so - update state (recipeId)
     */
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.match.params.recipeUrl !== prevState.recipeUrl){
            return {
                recipeUrl: nextProps.match.params.recipeUrl,
                loading: true
            };
        }
        else return null;
    }


    /**
     * Updates recipe instructions
     */
    onInstructionsChange = (instructions) => {

        let updatedRecipe = {...this.state.recipe, instructions: instructions };

        this.setState({
            recipe: updatedRecipe,
        });
    };

    /**
     * Update recipe inputs
     */
    onInputChange = (target, value) => {

        let updatedRecipe = {...this.state.recipe, [target]: value };

        this.setState({
            recipe: updatedRecipe,
        });

    };

    /**
     * Create a duration object, given minutes
     * @duration, (minutes) duration of recipe
     */
    getDurationObject = (duration) => {

        let durationObject = {
            minutes: duration,
            category: null
        };

        if (duration <= 20) {
            durationObject.category = 'Rask'
        } else if (duration <= 40) {
            durationObject.category = 'Medium'
        } else {
            durationObject.category = 'Lang'
        }

        return durationObject;
    };

    /**
     * Update dietary object based on input
     * @allergen, dietary in question
     * @value boolean
     */
    getDietaryObject = (allergen, value) => {

        let dietaryObject = {
            ...this.state.recipe.dietary,
            [allergen]: value,
        };

        return dietaryObject;
    };

    /**
     * Update ingredient object based on input
     * @target, name, amount, or unit
     */
    ingredientOnChange = (target, value, index) => {
        let updatedIngredient = {
            ...this.state.recipe.ingredients[index],
            [target]: value,
        };

        let updatedIngredientList = [...this.state.recipe.ingredients];

        updatedIngredientList[index] = updatedIngredient;

        let updatedRecipe = {...this.state.recipe, ingredients: updatedIngredientList };

        this.setState({
            recipe: updatedRecipe,
        });
    };

    /**
     * Add empty ingredient to list of ingredients
     */
    addIngredient = (e) => {
        e.preventDefault();

        let emptyIngredient = {
            unit: '',
            amount: '',
            name: '',
            category: ''
        };

        let updatedIngredientList = [...this.state.recipe.ingredients];

        updatedIngredientList.push(emptyIngredient);

        let updatedRecipe = {...this.state.recipe, ingredients: updatedIngredientList };

        this.setState({
            recipe: updatedRecipe,
        });
    };

    handleKeyPress = (event) => {
        //event.preventDefault();

        if (event.key === 'Enter'){
            // 13 is keyCode for 'Enter'
            this.addIngredient(event);
        }
    };

    /**
     * Remove ingredient from list of ingredients
     */
    deleteIngredient = (e, index) => {
        e.preventDefault();

        let updatedIngredientList = [...this.state.recipe.ingredients];

        updatedIngredientList.splice(index, 1);

        let updatedRecipe = {...this.state.recipe, ingredients: updatedIngredientList };

        this.setState({
            recipe: updatedRecipe,
        });
    };

    /**
     * Convert title to a url
     */
    createUrl = (title) => {
        let url = title.replace(/\s/g, '-').toLowerCase();

        let noAEOAA = url.replace(/æ/g, 'ae').replace(/ø/g, 'o').replace(/å/g, 'aa');

        return noAEOAA;
    };

    // ID Generator
    uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    };

    /**
     * Submit
     */
    async onSubmit(e) {
        e.preventDefault();

        this.setState({
            saving: true
        });

        let id = this.state.recipe.id ? this.state.recipe.id : this.uuidv4();

        // Firebase save recipe

        let url = this.createUrl(this.state.recipe.title);

        // Parse objects to strings
        let ingredientListString = JSON.stringify(this.state.recipe.ingredients);
        //let dietaryString = JSON.stringify(this.state.recipe.dietary);
        //let durationString = JSON.stringify(this.state.recipe.duration);
        let lastEdited = JSON.stringify(new Date());
        let timestamp = this.state.recipe.timestamp ? this.state.recipe.timestamp : JSON.stringify(new Date());

        let updatedRecipe = {
            ...this.state.recipe,
            id: id,
            ingredients: ingredientListString,
            //dietary: dietaryString,
            //duration: durationString,
            url: url,
            lastEdited: lastEdited,
            timestamp: timestamp,
            image: !!this.state.localImageUrl,
        };

        await firebase.saveRecipe(updatedRecipe);

        if (this.state.localImageUrl) {
            // Firebase upload image
            await firebase.saveRecipeImage(id, this.state.localImageFile);
        } else if (!this.state.localImageUrl && !this.state.recipeImageUrl) {
            // Either new recipe without image, or recipe with deleted image
            if (this.state.recipe.image) {
                //Recipe already exist with an image
                await firebase.deleteRecipeImage(this.state.recipe);
            }
        }

        // Fake loading time
        setTimeout(() => {
            this.setState({
                saving: false
            });
            this.props.history.push('/recipes/' + url);
        }, 1000);

    };

    /**
     * Cencel recipe edit/create
     */
    onCancel = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    };


    /**
     * Remove image
     */
    removeImage = (e, target) => {
      e.preventDefault();

        this.setState({
            [target]: null
        });
    };


    render() {

        return (
            <div style={{overflow: 'scroll'}}>
                {this.state.loading
                    ?
                    <div className="notLandingPage">
                        <Grid  container className="loadingPage" spacing={0}>
                            <Grid item className="loadingPageContainer" xs={10} md={9}>
                                <Loading />
                            </Grid>
                        </Grid>
                    </div>
                    :
                    <Grid container className="notLandingPage" spacing={0}>

                        <Grid item className="editRecipePage" xs={10} md={9}>

                            <form id="editRecipeForm" onSubmit={(event) => this.onSubmit(event)} className="editRecipePageContainer">

                                <Grid container spacing={24}>

                                    <Grid item className="formLabelContainer" xs={4} sm={3}>
                                        <p>Navn:</p>
                                    </Grid>

                                    <Grid item xs={8} sm={8}>
                                        <input className="editPageInput"
                                               type="text" id="name"
                                               placeholder=""
                                               value={this.state.recipe.title ? this.state.recipe.title : ''}
                                               required={true}
                                               onChange={ event => this.onInputChange('title', event.target.value) } />
                                    </Grid>

                                </Grid>

                                <Grid container spacing={24}>

                                    <Grid item className="formLabelContainer" xs={4} sm={3}>
                                        <p>Varighet:</p>
                                    </Grid>

                                    <Grid item style={{display: 'flex', flexDirection: 'row'}} xs={8} sm={8}>
                                        <input className="editPageInputNumber"
                                               type="number"
                                               id="duration"
                                               placeholder=""
                                               required={true}
                                               value={this.state.recipe.duration ? this.state.recipe.duration.minutes : ''}
                                               onChange={ event => this.onInputChange('duration', this.getDurationObject(event.target.value)) }/>
                                        <p>minutter</p>
                                    </Grid>

                                </Grid>

                                {/*
                                <Grid container spacing={24}>

                                    <Grid item className="formLabelContainer" xs={3}>
                                        <p>Kategori:</p>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <input className="editPageInput" type="text" id="category" placeholder=""/>
                                    </Grid>

                                </Grid>
                                */}

                                <Grid container spacing={24}>

                                    <Grid item className="formLabelContainer" xs={7} sm={3}>
                                        <p>Vanskelighetsgrad:</p>
                                    </Grid>

                                    <Grid item className="radioButtonsContainer" xs={4} sm={8}>

                                        <Grid container spacing={0}>

                                            {difficulties.map((difficulty, index) => {
                                                return (
                                                    <Grid key={index} item xs={12} md={4}>
                                                        <label className="radioButtonContainer"><p>{difficulty}</p>
                                                            <input type="radio"
                                                                   name="difficulty"
                                                                   value={difficulty}
                                                                   checked={this.state.recipe.difficulty===difficulty}
                                                                   required={true}
                                                                   onChange={ event => this.onInputChange('difficulty', event.target.value) } />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </Grid>
                                                );
                                            })}

                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Grid container spacing={24}>

                                    <Grid item className="formLabelContainer" xs={4} sm={3}>
                                        <p>Måltid:</p>
                                    </Grid>

                                    <Grid item xs={8} sm={8}>
                                        <select id="selectMealForm"
                                                form="editRecipeForm"
                                                name="mealList"
                                                defaultValue={this.state.recipe.meal}
                                                onChange={ event => this.onInputChange('meal', event.target.value) }>
                                            <option value="Frokost">Frokost</option>
                                            <option value="Gjærbakst">Gjærbakst</option>
                                            <option value="Kake">Kake</option>
                                            <option value="Lunsj">Lunsj</option>
                                            <option value="Middag">Middag</option>
                                            <option value="Dessert">Dessert</option>
                                            <option value="Kveldsmat">Kveldsmat</option>
                                            <option value="Annet">Annet</option>
                                        </select>
                                    </Grid>

                                </Grid>

                                <Grid container spacing={24}>

                                    <Grid item className="formLabelContainer" xs={4} sm={3}>
                                        <p>Allergener:</p>
                                    </Grid>

                                    <Grid item className="dietaryCheckboxesContainer" xs={4} sm={8}>

                                        <Grid container spacing={0}>

                                            {allergener.map((allergen, index) => {
                                                return(
                                                    <Grid key={index} item xs={12} sm={4}>
                                                        <label className="dietaryCheckboxContainer"><p>{allergen}</p>
                                                            <input type="checkbox"
                                                                   name="dietary"
                                                                   value={allergen}
                                                                   checked={this.state.recipe.dietary[allergen.toLowerCase()]}
                                                                   onChange={ event => this.onInputChange('dietary', this.getDietaryObject(allergen.toLowerCase(), event.target.checked)) } />
                                                            <span className="checkbox" />
                                                        </label>
                                                    </Grid>
                                                );
                                            })}

                                        </Grid>

                                    </Grid>

                                </Grid>

                                <Grid container spacing={24}>

                                    <Grid item className="formLabelContainer" xs={4} sm={3}>
                                        <p>Bilde:</p>
                                    </Grid>

                                    <Grid item xs={8} sm={8}>
                                        <input type="file"
                                               id="recipeImg"
                                               name="recipeImg"
                                               accept="image/jpg, image/jpeg"
                                               onChange={this.handleChange} />
                                    </Grid>

                                </Grid>

                                {this.state.localImageUrl ?
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}>

                                            <div className="imagePreviewContainer"
                                                 style={{backgroundImage: `url(${this.state.localImageUrl})`}}>
                                                <button className="buttonAddIngredient" onClick={(event) => this.removeImage(event, 'localImageUrl')}>
                                                    <DeleteOutlined />
                                                </button>
                                            </div>

                                        </Grid>
                                    </Grid>
                                    :
                                    this.state.recipeImageUrl ?
                                        <Grid container spacing={24}>
                                            <Grid item xs={12}>

                                                <div className="imagePreviewContainer"
                                                     style={{backgroundImage: `url(${this.state.recipeImageUrl})`}}>
                                                    <button className="buttonAddIngredient" onClick={(event) => this.removeImage(event, 'recipeImageUrl')}>
                                                        <DeleteOutlined />
                                                    </button>
                                                </div>

                                            </Grid>
                                        </Grid>
                                        :
                                        null
                                }

                                <Grid container spacing={24}>

                                    <Grid item className="formLabelContainer" xs={4} sm={3}>
                                        <p>Antall porsjoner:</p>
                                    </Grid>

                                    <Grid item xs={8} sm={8}>
                                        <input className="editPageInputNumber"
                                               type="number"
                                               id="numPeople"
                                               placeholder=""
                                               required={true}
                                               value={this.state.recipe.numPeople ? this.state.recipe.numPeople : ''}
                                               onChange={ event => this.onInputChange('numPeople', event.target.value) } />
                                    </Grid>

                                </Grid>

                                <Grid container spacing={24}>

                                    <Grid item className="formLabelContainer" xs={4} sm={3}>
                                        <p>Ingredienser:</p>
                                    </Grid>

                                    <Grid item xs={8} sm={8}>
                                        {this.state.recipe.ingredients.map((ingredient, index) => {
                                            return(
                                                <Grid container spacing={24} key={index} className="ingredientsInputContainer">
                                                    <Grid item xs={6} sm={1}>
                                                        <input className="editPageInputNumber"
                                                               type="number"
                                                               id={"ingredientAmount" + index}
                                                               autoFocus={true}
                                                               placeholder="0"
                                                               required={false}
                                                               onChange={ event => this.ingredientOnChange("amount", event.target.value, index)}
                                                               value={this.state.recipe.ingredients[index].amount} />
                                                    </Grid>
                                                    <Grid item xs={6} sm={2}>
                                                        <input className="editPageInput"
                                                               type="text"
                                                               id={"ingredientUnit" + index}
                                                               placeholder="Enhet"
                                                               required={false}
                                                               onChange={ event => this.ingredientOnChange("unit", event.target.value, index)}
                                                               value={this.state.recipe.ingredients[index].unit} />
                                                    </Grid>

                                                    <Grid item xs={6} sm={5}>
                                                        <input className="editPageInput"
                                                               type="text"
                                                               id={"ingredientName" + index}
                                                               placeholder="Ingrediens"
                                                               required={true}
                                                               onKeyPress={this.handleKeyPress}
                                                               onChange={ event => this.ingredientOnChange("name", event.target.value, index)}
                                                               value={this.state.recipe.ingredients[index].name} />
                                                    </Grid>

                                                    <Grid item xs={4} sm={3}>
                                                        <input className="editPageInput"
                                                               type="text"
                                                               id={"ingredientCategory" + index}
                                                               placeholder="Kategori (Valgfri)"
                                                               required={false}
                                                               onKeyPress={this.handleKeyPress}
                                                               onChange={ event => this.ingredientOnChange("category", event.target.value ? event.target.value : 'main', index)}
                                                               value={this.state.recipe.ingredients[index].category === 'main' ? null : this.state.recipe.ingredients[index].category} />
                                                    </Grid>

                                                    <Grid item xs={2} sm={1}>
                                                        <button className="deleteIngredientButton" onClick={(event) => this.deleteIngredient(event, index)}>
                                                            <DeleteOutlined />
                                                        </button>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}

                                        <Grid container spacing={24} className="ingredientsInputContainer">
                                            <Grid item xs={12}>
                                                <button className="buttonAddIngredient" onClick={(event) => this.addIngredient(event)}>+</button>
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </Grid>

                                <Grid container className="richEditor" spacing={24}>

                                    <Grid item className="" xs={10}>
                                        <RichEditor initInstructions={this.state.recipe.instructions ? this.state.recipe.instructions : null}
                                                    onInstructionsChange={this.onInstructionsChange} />

                                    </Grid>

                                </Grid>

                                <Grid container spacing={24} className="ingredientsInputContainer">
                                    <Grid item xs={false} sm={7} md={8}>

                                    </Grid>

                                    {this.state.saving ?
                                        <Grid item xs={12} sm={5} md={4}>
                                            <Loading/>
                                        </Grid>
                                        :
                                        <Grid item xs={12} sm={5} md={4}>
                                            <button className="saveButton" type="submit" value="Submit">Lagre</button>
                                            <button className="cancelButton" onClick={(e) => this.onCancel(e)}>Avbryt
                                            </button>
                                        </Grid>
                                    }
                                </Grid>

                            </form>

                        </Grid>

                    </Grid>
                }
            </div>
        );
    }
}

// Condition used in the authorization process
// Checks whether the user object is null or not
const authCondition = (authUser) => !!authUser;

// Using the higher order component 'withAuthorization' to protect the page
export default withAuthorization(authCondition)(withRouter(EditRecipePage));