import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './rootStyles.css'
import 'typeface-roboto';
import Favicon from 'react-favicon';

import withAuthentication from './higherOrderComponents/withAuthentication';
import * as routes from './constants/routes';
import AdminPage from './routes/AdminPage';
import LandingPage from './routes/LandingPage';
import LoginPage from './routes/LoginPage';
import RecipePage from './routes/RecipePage';
import RecipesPage from './routes/RecipesPage';
import PageNotFound_404 from './routes/PageNotFound_404';
import EditRecipePage from './routes/EditRecipePage';


class App extends Component {

    state = {

    };

    componentDidMount() {
        document.title = "Britts Kokebok";
    };

    render() {

        return (
            <Router>
                <div>
                    <Favicon url={require('./img/faviconPink.ico')} />

                    <LandingPage />

                    <div className="router" style={{overflow: 'hidden'}}>

                        <Switch>
                            <Route
                                exact path={routes.NEW_RECIPE}
                                component={EditRecipePage}
                            />
                            <Route
                                exact path={routes.LANDING}
                                component={null}
                            />
                            <Route
                                exact path={routes.ADMIN}
                                component={AdminPage}
                            />
                            <Route
                                exact path={routes.LOGIN}
                                component={LoginPage}
                            />
                            <Route
                                exact path={routes.RECIPES}
                                component={RecipesPage}
                            />
                            <Route
                                exact path={routes.RECIPE}
                                component={RecipePage}
                            />
                            <Route
                                exact path={routes.EDIT_RECIPE}
                                component={EditRecipePage}
                            />
                            <Route component={PageNotFound_404} />
                        </Switch>

                    </div>
                </div>
            </Router>
        );
    }
}

// Use the higher order component 'withAuthentication' (which I made/copied) to take care of authentication
export default withAuthentication(App);