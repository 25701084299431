/**
 * Created by jwjorgen on 15/11/2018.
 */

import React from 'react';
import './styles.css';


const ButtonSignIn = () => {

    return(
        <div className="signInAndOutButton">
            LOGG INN
        </div>
    );
};

export default ButtonSignIn;