/**
 * Created by jwjorgen on 13/11/2018.
 */

import React from 'react';


/**
 * Context for the entire app, regarding authentication
 */
const AuthUserContext = React.createContext(null);

export default AuthUserContext;