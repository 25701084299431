/**
 * Created by jwjorgen on 12/11/2018.
 */

import React, { Component } from 'react';
import { auth } from '../firebase';


const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};


class SignInForm extends Component {

    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {

        // Have to focu the input this way, to prevent weird loading of page
        // The normal way will scroll down the page, which is unfortunate as scrolling
        // back up is disabled.
        setTimeout(
            () => this.emailInput.focus()
            , 100
        );
    };

    onSubmit = (event) => {

        const {
            email,
            password,
        } = this.state;

        const {
            history,
        } = this.props;

        auth.doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                history.goBack();
            })
            .catch(error => {
                this.setState({error: error});
            });

        event.preventDefault();

    };

    render() {

        const {
            email,
            password,
            error,
        } = this.state;

        const isInvalid =
            password === '' ||
            email === '';

        return (
            <form className="formContainer" onSubmit={this.onSubmit}>

                <div className="formContent">

                    <input
                        value={email}
                        onChange={event => this.setState({email: event.target.value})}
                        type="text"
                        placeholder="E-post"
                        ref={(input) => { this.emailInput = input; }}
                    />
                    <input
                        value={password}
                        onChange={event => this.setState({password: event.target.value})}
                        type="password"
                        placeholder="Passord"
                    />
                    <button type="submit" disabled={isInvalid}>
                        Logg Inn
                    </button>

                    { error && <p>{error.message}</p> }

                </div>

            </form>
        );
    }
}

export default SignInForm;