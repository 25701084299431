/**
 * Created by jwjorgen on 25/11/2018.
 */

import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';


class Loading extends Component {

    render() {

        return(
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <ClipLoader
                    sizeUnit={"px"}
                    size={50}
                    color='#ff709c'
                    loading={true}
                />
            </div>
        );

    }
}

export default Loading;