/**
 * Created by jwjorgen on 16/11/2018.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import * as routes from '../constants/routes';


const ButtonAllRecipes = () => {

    return(
        <div className="button links allRecipes">
            <Link to={routes.RECIPES}>
                OPPSKRIFTER
            </Link>
        </div>
    );
};

export default ButtonAllRecipes;