/**
 * Created by jwjorgen on 13/12/2018.
 */

import React  from 'react';
import { firebase } from '../firebase';

import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import './theme.css';

const suggestions = [
];


function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.title, query);
    const parts = parse(suggestion.title, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => {
                    return part.highlight ? (
                            <span key={String(index)} style={{ fontWeight: 500 }}>
                                {part.text}
                            </span>
                        ) : (
                            <strong key={String(index)} style={{ fontWeight: 300 }}>
                                {part.text}
                            </strong>
                        );
                })}
            </div>
        </MenuItem>
    );
}

function getSuggestions(value) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
        ? []
        : suggestions.filter(suggestion => {
            const keep =
                count < 5 && suggestion.title.slice(0, inputLength).toLowerCase() === inputValue;

            if (keep) {
                count += 1;
            }

            return keep;
        });
}

function getSuggestionValue(suggestion) {
    return suggestion.title;
}

class SearchBarV3 extends React.Component {
    state = {
        single: '',
        popper: '',
        suggestions: [],
    };

    async componentDidMount() {
        let allRecipes = await firebase.getAllRecipes();

        allRecipes.map((recipe) => {
            suggestions.push({title :recipe.title, url: recipe.url});
            return null; // Had to return null to get rid of console warning
        });
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = name => (event, { newValue }) => {
        this.setState({
            [name]: newValue,
        });
    };

    removeSuggestionValue = () => {
        this.setState({
            single: '',
        });
    };

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.props.history.push('/recipes/' + suggestion.url);
        this.removeSuggestionValue();
    };

    render() {

        const autosuggestProps = {
            /*renderInputComponent,*/
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            onSuggestionSelected: this.onSuggestionSelected,
            highlightFirstSuggestion: true,
            getSuggestionValue,
            renderSuggestion,
        };

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Søk i Britts Kokebok...',
            value: this.state.single,
            onChange: this.handleChange('single'),
            autoFocus: true
        };

        return (
            <Autosuggest
                {...autosuggestProps}
                inputProps={inputProps}
                renderSuggestionsContainer={options => (
                    <Paper {...options.containerProps} square>
                        {options.children}
                    </Paper>
                )}
            />
        );
    }
}

export default SearchBarV3;
