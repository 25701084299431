/**
 * Created by jwjorgen on 27/11/2018.
 */

import React from 'react';
import './styles.css';


const ButtonNewRecipe = () => {

    return(
        <div className="buttonEdit">
            NY OPPSKRIFT
        </div>
    );
};

export default ButtonNewRecipe;