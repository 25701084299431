/**
 * Created by jwjorgen on 15/12/2018.
 */

import React from 'react';
import './styles.css';


const ButtonSignOut = () => {

    return(
        <div className="signInAndOutButton">
            LOGG UT
        </div>
    );
};

export default ButtonSignOut;