/**
 * Created by jwjorgen on 27/11/2018.
 */

import React from 'react';
import './styles.css';


const ButtonDelete = () => {

    return(
        <div className="buttonDelete">
                SLETT
        </div>
    );
};

export default ButtonDelete;