/**
 * Created by jwjorgen on 13/11/2018.
 */

import React from 'react';
import { withRouter } from 'react-router-dom';

import AuthUserContext from '../context/AuthUserContext';
import { firebase } from '../firebase';
import * as routes from '../constants/routes';


/**
 * Higher order component that checks whether the user is authorized or not
 */
const withAuthorization = (authCondition) => (Component) => {

    class WithAuthorization extends React.Component {

        componentDidMount() {
            firebase.auth.onAuthStateChanged(authUser => {
                if (!authCondition(authUser)) {
                    this.props.history.push(routes.LOGIN);
                }
            });
        }

        render() {
            return (
                <AuthUserContext.Consumer>
                    {authUser => authUser ? <Component {...this.props} /> : null}
                </AuthUserContext.Consumer>
            );
        }
    }

    return withRouter(WithAuthorization);
};

export default withAuthorization;