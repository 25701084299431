/**
 * Created by jwjorgen on 21/10/2018.
 */

import * as firebase from "firebase";

const prodConfig = {
    apiKey: "AIzaSyBOoa3t5zUvlG2z5AzxYd1p7R47nFd-1bE",
    authDomain: "britts-kokebok.firebaseapp.com",
    databaseURL: "https://britts-kokebok.firebaseio.com/",
    storageBucket: "gs://britts-kokebok.appspot.com"
};

const devConfig = {
    apiKey: "AIzaSyBOoa3t5zUvlG2z5AzxYd1p7R47nFd-1bE",
    authDomain: "britts-kokebok.firebaseapp.com",
    databaseURL: "https://britts-kokebok.firebaseio.com/",
    storageBucket: "gs://britts-kokebok.appspot.com"
};

const config = process.env.NODE_ENV === 'production'
    ? prodConfig
    : devConfig;

firebase.initializeApp(config);

// Get authentication API
const auth = firebase.auth();

export {
    auth,
};

// **************************************************************
//                      DATABASE OPERATIONS
// **************************************************************

// Get a database reference (JSON)
export const databaseRef = firebase.database().ref();

// Get Storage Reference (Images)
const storageRef = firebase.storage().ref();


/**
 * Get all recipes in the database
 */
export const getAllRecipes = () => {

    return new Promise((resolve, reject) => {

        let allRecipes = [];

        databaseRef.child('recipes').on("value", (snapshot) => {
            snapshot.forEach( (recipeSnapshot) => {

                let recipe = recipeSnapshot.val();
                allRecipes = [...allRecipes, recipe];

            });

            resolve(allRecipes);

        }, (error) => {
            console.log("Error: " + error.code);
            reject(error);
        });

    });
};


/**
 * Get a specific recipe from the database
 */
export const getRecipe = (recipeUrl) => {

    return new Promise((resolve, reject) => {

        databaseRef.child('recipes').on("value", (snapshot) => {

            snapshot.forEach( (recipeSnapshot) => {
                let recipeObject = recipeSnapshot.val();

                if (recipeObject.url === recipeUrl) {
                    resolve(recipeObject);
                }
            });

        }, (error) => {
            console.log("Error: " + error.code);
            reject(error);
        });

    });
};


/**
 * Get all recipe titles in the database
 */
export const getAllRecipeUrls = () => {

    return new Promise((resolve, reject) => {

        let allRecipeUrls = [];

        databaseRef.child('recipes').on("value", (snapshot) => {
            snapshot.forEach( (recipeSnapshot) => {

                let recipe = recipeSnapshot.val();
                let url = recipe.url;
                allRecipeUrls = [...allRecipeUrls, url];

            });

            resolve(allRecipeUrls);

        }, (error) => {
            console.log("Error: " + error.code);
            reject(error);
        });

    });
};


/**
 * Get image of a recipe
 */
export const getRecipeImage = (recipeUrl) => {

    return new Promise((resolve, reject) => {

        let imageRef = storageRef.child('images/' + recipeUrl + '.jpg');

        imageRef.getDownloadURL().then( (url) => {

            resolve(url)

        }).catch( (error) => {

            //reject(error);
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case 'storage/object-not-found':
                    // File doesn't exist
                    //resolve(null);
                    reject(error.code);
                    break;

                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    reject(error.code);
                    break;

                case 'storage/unknown':
                    // Unknown error occurred, inspect the server response
                    reject(error.code);
                    break;

                default:
                    reject(error.code);
                    break;
            }
        });

    });
};


/**
 * Save recipe to the database
 */
export const saveRecipe = (recipe) => {

    return new Promise((resolve, reject) => {

        // Create a new recipe (overwriting existing)
        databaseRef.child('recipes/' + recipe.id).set(recipe).then(() => {
            resolve('Success');
        }).catch( (error) => {
            reject(error.code);
        });

    });
};


/**
 * Save recipe image to the database
 */
export const saveRecipeImage = (imageId, imageFile) => {

    return new Promise((resolve, reject) => {

        let uploadTask = storageRef.child('images/' + imageId + '.jpg').put(imageFile);

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            function(snapshot) {
            /*
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
                */
            }, function(error) {
                reject(error.code);

            }, function() {
                // Upload completed successfully, now we can get the download URL
                resolve('Success');
            });

        /*
        imageRef.put(imageFile).then(() =>  {
            resolve('Success');
        }).catch( (error) => {
            reject(error);
        });
        */
    });
};


/**
 * Delete recipe
 */
export const deleteRecipe = (recipe) => {

    databaseRef.child('recipes/' + recipe.id).remove();
    storageRef.child('images/' + recipe.url + '.jpg').delete();

    /*
    // Delete the file
    storageRef.child('images/' + recipe.url + '.jpg').delete().then(function() {
        // File deleted successfully
    }).catch(function(error) {
        // Uh-oh, an error occurred!
    });
    */
};

/**
 * Delete recipe image
 */
export const deleteRecipeImage = (recipe) => {

    return new Promise((resolve, reject) => {

        //storageRef.child('images/' + recipe.url + '.jpg').delete();

        let imageRef = storageRef.child('images/' + recipe.id + '.jpg');

        // Delete the file
        imageRef.delete().then(function () {
            // File deleted successfully
            resolve('Success');
        }).catch(function (error) {
            // Uh-oh, an error occurred!
            reject(error.code);
        });
    });
};