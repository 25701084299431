/**
 * Created by jwjorgen on 16/11/2018.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import * as routes from '../constants/routes';


const ButtonAdvanced = () => {

    return(
        <div className="button links advanced">
            <Link to={routes.LANDING}>
                AVANSERT
            </Link>
        </div>
    );
};

export default ButtonAdvanced;