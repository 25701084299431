import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
//import reduxThunk from "redux-thunk"; //Not sure if I'll use this
import reducer from './reducers/index';


import App from './App';
import * as serviceWorker from './serviceWorker';

// Temporary initial store
const initialState = {
    Example: {}
};

// Create the store to store stuff (Duh)
const store = createStore(
    reducer,
    initialState,
    applyMiddleware(/*reduxThunk*/)
);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

//serviceWorker.unregister();
serviceWorker.register();
