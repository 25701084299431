/**
 * Created by jwjorgen on 21/10/2018.
 */

import { combineReducers } from "redux";
import Example from './Example';


export default combineReducers({
    Example: Example,
})