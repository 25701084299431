/**
 * Created by jwjorgen on 21/10/2018.
 */

const Example = (state = [], action) => {
    switch (action.type) {

        case 'NOUN_VERB':
            return {
                ...state,
                field: action.bool
            };

        default:
            return state
    }
};

export default Example
