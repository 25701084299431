/**
 * Created by jwjorgen on 12/11/2018.
 */

import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';


class PageNotFound_404 extends Component {

    render() {

        return (
            <div>

                <Grid container className="notLandingPage" spacing={0}>

                    <Grid className="recipePageContainer" item xs={10} md={9}>

                        <div style={{height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign:'center'}}>

                            <p>Ojda... Denne oppskriften finnes ikke i Britts Kokebok :( Er du sikker på at
                            du skrev riktig oppskrift? Hvis det fortsatt ikke fungerer, ring Joachim :)</p>

                        </div>

                    </Grid>

                </Grid>

            </div>
        );
    }
}

export default PageNotFound_404;