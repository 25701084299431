/**
 * Created by jwjorgen on 18/11/2018.
 */

import React, { Component } from 'react';

import IngredientsList from '../components/IngredientsList';


class Ingredients extends Component {

    state = {
        displayPeople: this.props.numPeople
    };

    updateDisplayPeople = (numDisplayPeople) => {
        this.setState({
            displayPeople: numDisplayPeople
        })
    };

    render() {

        return (
            <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>

                <div style={{borderBottom: "solid 1px black"}}>
                    <h3>Ingredienser</h3>
                </div>

                <IngredientsList ingredients={this.props.ingredients}
                                 numPeople={this.props.numPeople}
                                 updateDisplayPeople={this.updateDisplayPeople}
                                 displayPeople={this.state.displayPeople} />

            </div>
        );
    }
}

export default Ingredients;