/**
 * Created by jwjorgen on 25/11/2018.
 */

import React, { Component } from 'react';

import SignInForm from '../containers/SignInForm';


class LoginContainer extends Component {


    render() {

        return (
            <div className="loginPageContainer">

                <SignInForm history={this.props.history} />

            </div>
        );
    }
}

export default LoginContainer;