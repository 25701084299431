/**
 * Created by jwjorgen on 12/11/2018.
 */

import React from 'react';

import { auth } from '../firebase';

const SignOutButton = () =>
    <button
        type="button"
        onClick={auth.doSignOut}
    >
        Sign Out
    </button>

export default SignOutButton;