/**
 * Created by jwjorgen on 18/11/2018.
 */

import React from 'react';
import { AccessTime, AssessmentOutlined, AccessibleForwardOutlined, HourglassEmptyOutlined } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';


export const RecipeTitle = props => {

    let diets = Object.keys(props.recipe.dietary);

    let activeDiets = diets.filter(diet => props.recipe.dietary[diet] === true);

    let capitalizedDiets = activeDiets.map( (diet, index) => {
        return diet.replace(/^\w/, c => c.toUpperCase());
    });

    return(
        <Grid container spacing={0} style={{color: 'white', backgroundColor: '#ff709c', padding: '12px'}}>

            <Grid item xs={6} sm={3} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <HourglassEmptyOutlined />
                <p>{props.recipe.duration.minutes} min</p>
            </Grid>

            <Grid item xs={6} sm={3} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <AssessmentOutlined />
                <p>{props.recipe.difficulty}</p>
            </Grid>

            <Grid item xs={6} sm={3} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <AccessTime />
                <p>{props.recipe.meal}</p>
            </Grid>

            <Grid item xs={6} sm={3} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <AccessibleForwardOutlined />
                <p>{capitalizedDiets}</p>
            </Grid>

        </Grid>
    );
};

export default RecipeTitle;